@font-face {
  font-family: Argon;
  src: url(fonts/Argon-Regular.otf);
}

html, body {
  font-family: 'Argon', sans-serif;
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-family: 'Argon', sans-serif;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  text-transform: uppercase;
  align-content: center;
  color: #ffffff !important;
}

.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.video{
  color: #000000;
}
.Menu-fondo{
  color:#000000;
}
.Menu-letras{
  height: 60px; 
}
.clearfixe{  height: 60px;}
.somos{
  background-color: transparent;
  padding: 30px 100px;
  text-align: center;
}
.section-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #46737B;
  padding-bottom: 20px;;
}
.section-desc-p{
  font-family: 'Argon', sans-serif;
}
.manita{
  cursor:pointer;
}
.pading{
  padding: 10px;
}
.section-div{
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 20px;;
}
.section-hover-inmobi{
  width: 85%;
  padding: 20px;
  text-align: center;
  background-image: url(./inmobi.png); 
  
  background-repeat: no-repeat;
  background-size: contain;
}
.section-hover-inmobi:hover{
 background-image: url(./inmobi_hover.png);
}
.section-hover-mg{
  width: 85%;
  padding: 20px;
  text-align: center;
  background-image: url(./mg.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.section-hover-mg:hover{
  background-image: url(./mg_hover.png);
}
.section-hover-mandarina{
  width: 85%;
  padding: 20px;
  text-align: center;
  background-image: url(./mandarina.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.section-hover-mandarina:hover{
  background-image: url(./mandarina_hover.png);
}
.section-hover-afirmativo {
  width: 85%;
  padding: 20px;
  text-align: center;
  background-image: url(./afirmativo.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.section-hover-afirmativo:hover {
  background-image: url(./afirmativo_hover.png);
}
.section-fakeimage{
  visibility: hidden;
}

.section-footer{
  color: #ffffff;
  background-color: #000000;
  font-size: 10px;
  text-align: center;
}

.player-style {
  width: 100% !important;
  height: calc( 100vw / 1.717 ) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.section-web {
  display: block;
}
.section-cel {
  display: none;
}
.icon-nav {
  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 460px)
{
  .Menu-letras{
    height: 20px; 
  }
  .clearfixe{  
    height: 52px;
  }
  .somos{
    padding: 15px 35px;
  }
  .section-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    padding: 20px ;;
  }
  .manita{
    display:  none;
  }
  .manita-cel{
    display:none;
    padding: 25px;
  }
  .section-web {
    display: none;
  }
  .section-cel {
    display: block;
  }

  .section-div {
    text-align: center;
  }
  .section-desc {
    text-align: center;
  }

}